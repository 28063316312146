import React, { useState } from "react";
import VideoBackground from "./VideoBackground";
import "./App.css";

function App() {
  console.log("UBIK");
  console.log("Mao II");
  console.log("MENTHOL MOUNTAINS");
  console.log(".");
  console.log(".");
  console.log(".");
  console.log(".");
  console.log("love is a dog");

  return (
    <div className="App">
      <VideoBackground />
    </div>
  );
}

export default App;
